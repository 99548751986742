@import '../../../assets/styles/Colors.scss';

.cb-quotes-basic-overview-table {
    border-radius: 10px;
    flex: 1 0 0;
    border: 1px solid rgba(34, 36, 38, 0.15);
    box-shadow: 0 2px 4px 0 rgba(34, 36, 38, 0.12), 0 2px 10px 0 rgba(34, 36, 38, 0.15);
    margin: 1em;
    user-select: none;
}

.cb-quotes-basic-overview-table .cb-table-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1em;
    background-color: #EEE;
    border-bottom: 1px solid lightgrey;
}

.cb-quotes-basic-overview-table .cb-table-header h3 {
    color: $segurtempo-violet;
    margin: 0 2em 0 0;
}

.cb-quotes-basic-overview-table .cb-table-header .cb-search-input {
    flex-grow: 1;
    max-width: 300px;
}

.cb-quotes-basic-overview-table .cb-table-wrapper {
    overflow-y: auto;
    height: 40vh;
    min-height: 350px;
}

.cb-quotes-basic-overview-table .cb-table-wrapper.cb-loading,
.cb-quotes-basic-overview-table .cb-table-wrapper.cb-empty {
    display: flex;
    justify-content: center;
    align-items: center;
}

.cb-quotes-basic-overview-table .cb-table-wrapper.cb-empty h1 {
    color: gray;
    text-align: center;
}

.cb-quotes-basic-overview-table .cb-table-wrapper table {
    display: block;
}

.cb-quotes-basic-overview-table .cb-table-wrapper table tbody {
    display: table;
    width: 100%;
}

.cb-quotes-basic-overview-table .cb-table-wrapper table tbody .cb-date-data {
    word-spacing: 0.3em;
}

.cb-quotes-basic-overview-table .cb-table-wrapper table tbody tr {
    cursor: pointer;
}

.cb-quotes-basic-overview-table .cb-table-wrapper table tbody tr:hover td {
    background-color: $segurtempo-violet;
}