@import '../../../assets/styles/Colors.scss';

.cb-overview-card {
    user-select: none;
    min-width: 170px;
}

.cb-overview-card:hover {
    background-color: $segurtempo-red!important;
}

.cb-overview-card .cb-overview-title {
    color: $segurtempo-grey!important;
}

.cb-overview-card:hover .cb-overview-title,
.cb-overview-card:hover .cb-overview-content {
    color: $segurtempo-white!important;
}