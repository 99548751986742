@import '../../../assets/styles/Colors.scss';

.cb-quote-management-panel .menu .active.item {
    font-weight: bold!important;
}

.cb-quote-management-panel .cb-quote-management-panel-menu {
    background: $segurtempo-violet!important;
    overflow-x: auto;
}

.cb-quote-management-panel .cb-quote-management-panel-menu .item {
    background: transparentize(white, 0.9)!important;
}

.cb-quote-management-panel .cb-quote-management-panel-menu .item:before {
    background: transparentize(white, 0.6)!important;
    width: 2px!important;
}

.cb-quote-management-panel .cb-quote-management-panel-menu .item:hover {
    background: transparentize(white, 0.8)!important;
}

.cb-quote-management-panel .cb-quote-management-panel-menu .item.active {
    background: transparentize($segurtempo-sky-blue, 0.5)!important;
    color: white!important;
}

.cb-quote-management-panel .cb-quote-form-pane,
.cb-quote-management-panel .cb-quote-public-files-pane,
.cb-quote-management-panel .cb-quote-private-files-pane,
.cb-quote-management-panel .cb-quote-actions-pane,
.cb-quote-management-panel .cb-quote-communication-pane {
    padding: 0!important;
}

.cb-quote-management-panel .cb-quote-public-files-pane,
.cb-quote-management-panel .cb-quote-private-files-pane,
.cb-quote-management-panel .cb-quote-actions-pane {
    background-color: #F3F3F3!important;
}

.cb-quote-management-panel .cb-quote-communication-pane {
    background-color: #333!important;
    border: solid black!important;
    border-width: 0 1px 1px 1px!important;
}