@import '../../../assets/styles/Colors.scss';


//--------------
//- Primary
//--------------
.cb-sidebar-link.cb-primary {
    color: transparent-white(0.9)!important;
}

.cb-sidebar-link.cb-primary:not(.cb-no-link):hover {
    background-color: transparent-white(0.08)!important;
}

.cb-sidebar-link.cb-primary.cb-active {
    background-color: transparent-white(0.15)!important;
    border-top: 1px solid transparent-white(0.08)!important;
    border-bottom: 1px solid transparent-white(0.08)!important;
}

.cb-sidebar-link.cb-primary.cb-smaller-padding {
    padding: 0.65em 1.2em!important;
}

.cb-sidebar-link.cb-primary .cb-sidebar-link-content {
    font-size: 1.1em!important;
}

.cb-sidebar-link.cb-primary .cb-drafts-amount-label {
    background-color: $segurtempo-sky-blue!important;
    color: $segurtempo-white!important;
}


//--------------
//- Secondary
//--------------
.cb-sidebar-link.cb-secondary {
    color: transparent-white(0.5)!important;
}

.cb-sidebar-link.cb-secondary.cb-active {
    font-weight: bold!important;
}

.cb-sidebar-link.cb-secondary.cb-smaller-padding {
    padding: 0.3em 1.2em 0.3em 2.2em!important;
}

.cb-sidebar-link.cb-secondary .cb-drafts-amount-label {
    background-color: $segurtempo-white!important;
    color: #222!important;
}


//--------------
//- Generic
//--------------
.cb-sidebar-link {
    display: block!important;
    border-top: 1px solid transparent!important;
    border-bottom: 1px solid transparent!important;
}

.cb-sidebar-link:not(.cb-smaller-padding) {
    padding: 0.8em 1.2em!important;
}

.cb-sidebar-link:not(.cb-no-link):hover,
.cb-sidebar-link.cb-active {
    color: white!important;
}

.cb-sidebar-link-content {
    display: flex!important;
    justify-content: space-between!important;
    align-items: center!important;
}

.cb-sidebar-link-content .icon {
    margin-right: 0.5em!important;
}

.cb-sidebar-link + .cb-status-hint {
    display: block;
    position: fixed;
    overflow: hidden;
    background-color: $segurtempo-white;
    opacity: 0.9;
    padding: 0.5em 1em;
    color: #333;
    border: 1px solid $segurtempo-white;
    border-radius: 5px;
    font-size: small;
    font-style: italic;
    width: 17%;
    min-width: 250px;
    z-index: 3;
    pointer-events: none;
}