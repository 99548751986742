@import '../../assets/styles/Colors.scss';

.cb-quotes-search-wrapper .cb-quotes-search {
    margin-top: 3em;
    border: 1px solid rgba(34, 36, 38, 0.15);
    border-radius: 4px;
    background: $segurtempo-sky-blue;
    opacity: 0.85;
}

.cb-quotes-search-wrapper .cb-quotes-search > header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.3em 3.75em;
    border-bottom: 1px solid rgba(34, 36, 38, 0.15);
}

.cb-quotes-search-wrapper .cb-quotes-search > header .cb-search-input {
    display: flex;
    max-width: 50%;
    width: 50%;
    flex-grow: 1;
}

.cb-quotes-search-wrapper .cb-quotes-search > header .cb-search-input input {
    border: 2px solid #2A4365!important;
}

.cb-quotes-search-wrapper .cb-quotes-search > header .cb-info-label strong {
    margin-right: 0.3em;
}

.cb-quotes-search-wrapper .cb-quotes-search .cb-info-message,
.cb-quotes-search-wrapper .cb-quotes-search .cb-empty-message {
    margin: 2em auto!important;
    width: 40vw!important;
}

.cb-quotes-search-wrapper .cb-quotes-search .cb-found-quotes {
    max-height: 68vh;
    overflow-y: auto;
    padding: 2.3em 3.75em;
    margin: 0!important;
}

.cb-quotes-search-wrapper .cb-quotes-search .cb-found-quotes .cb-found-quote {
    color: $segurtempo-violet;
    user-select: none;
}

.cb-quotes-search-wrapper .cb-quotes-search .cb-found-quotes .cb-found-quote .content .header {
    color: #555!important;
}

.cb-quotes-search-wrapper .cb-quotes-search .cb-found-quotes .cb-found-quote .content .header + .meta i {
    margin-left: 0.3em;
}

.cb-quotes-search-wrapper .cb-quotes-search .cb-found-quotes .cb-found-quote .content .list .item strong {
    margin-right: 0.3em;
}

.cb-quotes-search-wrapper .cb-quotes-search .cb-found-quotes .cb-found-quote .content .list .item i {
    margin-left: 0.2em;
}

.cb-quotes-search-wrapper .cb-quotes-search .cb-found-quotes .cb-found-quote .extra.content {
    color: transparentize(black, 0.3);
}

@media only screen and (max-width: 767px) {
    .cb-quotes-search-wrapper .cb-quotes-search > header .cb-search-input {
        max-width: 100%;
        width: 100%;
    }

    .cb-quotes-search-wrapper .cb-quotes-search .cb-info-message,
    .cb-quotes-search-wrapper .cb-quotes-search .cb-empty-message {
        width: 90%!important;
    }
}