@import '../../assets/styles/Colors.scss';

.cb-notifications-widget {
    position: relative;
    margin-right: 1em;
    top: 1.35em;
    user-select: none;
    z-index: 1;
}

@media only screen and (max-width: 767px) {
    .cb-notifications-widget {
        right: 0;
    }
}

.cb-notifications-widget .cb-main-content {
    position: relative;
    display: flex;
    padding: 1em 1em;
    cursor: pointer;
    border: 1px solid transparent;
    border-radius: 50%;
    background-color: transparent-white(0.9);
    box-shadow: 0px 0px 3px #BBB;
}

.cb-notifications-widget .cb-main-content:hover {
    background-color: #F2F2F2;
    box-shadow: 0px 0px 3px #AAA;
}

.cb-notifications-widget .cb-main-content .icon {
    font-size: 1.4em;
    color: $segurtempo-violet;
    margin: 0;
}

.cb-notifications-widget .cb-main-content .label {
    top: -0.3em!important;
    left: 93%!important;
}

.cb-notifications-manager {
    position: absolute!important;
    top: calc(100% + 1em);
    left: 50%;
    transform: translateX(-50%);
    min-width: 300px;
    background-color: white;
    z-index: 2;
    margin: 0!important;
    padding: 0!important;
}


@media only screen and (max-width: 767px) {
    .cb-notifications-manager {
        left: auto;
        right: 0;
        transform: none;
    }
}

.cb-notifications-manager > header > .button {
    border-bottom-left-radius: 0!important;
    border-bottom-right-radius: 0!important;
}

.cb-notifications-manager > header > .button,
.cb-notifications-manager > header > .button:active,
.cb-notifications-manager > header > .button:visited,
.cb-notifications-manager > header > .button:focus {
    background-color: #DBDBDB;
}

.cb-notifications-manager > header > .button:hover {
    background-color: #DFDFDF;
}

.cb-notifications-manager .cb-notifications-list {
    max-height: 300px;
    overflow-y: auto;
}

.cb-notification {
    position: relative;
    display: flex;
    padding-right: 1em;
}

.cb-notification:not(:last-child) {
    border-bottom: 1px solid transparent-black(0.1);
}

.cb-notification:nth-child(odd) {
    background-color: transparent-black(0.05);
}

.cb-notification:hover {
    background-color: transparentize($segurtempo-sky-blue, 0.85);
    cursor: pointer;
}

.cb-notification .cb-read-marker {
    width: 0.3em;
    margin-right: 1em;
    flex-shrink: 0;
}

.cb-notification.cb-unread .cb-read-marker {
    background-color: transparentize($segurtempo-sky-blue, 0.3);
}

.cb-notification:not(.cb-unread) .cb-read-marker {
    background-color: $segurtempo-sky-blue;
}

.cb-notification .cb-notification-message {
    padding: 1.7em 0 0.8em 0;
    color: $segurtempo-violet;
    word-spacing: 1px;
    font-style: italic;
}

.cb-notification.cb-unread .cb-notification-message {
    font-weight: bold;
}

.cb-notification .cb-notification-datetime {
    position: absolute;
    top: 0;
    left: 1.3rem;
    font-size: smaller;
    color: grey;
    border-bottom: 1px dashed grey;
}

.cb-notifications-manager > footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid grey;
    padding: 0.6em 1em;
    color: $segurtempo-violet;
}

.cb-notifications-manager > footer > .cb-page-link {
    margin: 0;
}

.cb-notifications-manager > footer > .cb-page-link:not(.cb-disabled) {
    color: $segurtempo-violet!important;
    cursor: pointer;
}

.cb-notifications-manager > footer > .cb-page-link:not(.cb-disabled):hover {
    color: $segurtempo-sky-blue!important;
}

.cb-notifications-manager > footer > .cb-page-link.cb-disabled {
    color: transparentize($segurtempo-violet, 0.8)!important;
}

