@import '../../../assets/styles/Colors.scss';

.cb-sidebar {
    min-width: 230px;
    max-width: 230px;
    background-color: $segurtempo-violet;
    box-shadow: 0px 0px 7px #111;
    user-select: none;
    overflow-y: auto;
    padding-bottom: 2em;
    position: relative;
    height: 100%;
}

.cb-sidebar > header {
    display: flex;
    align-items: center;
    padding: 2.5em 2.2em;
    cursor: default;
}

.cb-sidebar > header > img {
    height: 40px;
}

.cb-sidebar > header > h3 {
    margin: 0 0 0 0.5em;
    color: $light-grey;
}

.sidebar-logo {
    max-height: 21px;
    width: auto;
    display: block;
    margin: 0 auto; 
    margin-left: -10px;
  }

.powered-by {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    padding: 10px 0;
    color: $segurtempo-white;
    background-color: $segurtempo-violet;
}
  

@media only screen and (max-width: 767px) {
    .cb-sidebar {
        display: none;
    }
}