@import '../../../assets/styles/Colors.scss';

.cb-access-requests-pagination-wrapper {
    display: flex;
    width: 100%;
    justify-content: center;
    padding-top: 3vw;
}

.cb-access-requests-pagination {
    border: none!important;
    box-shadow: none!important;
    flex-wrap: wrap!important;
}

.cb-access-requests-pagination .item::before {
    content: none!important;
}

.cb-access-requests-pagination .item {
    border-radius: 50%!important;
    margin: 0 0.4em 0.4em 0.4em!important;
    font-weight: bold!important;
    font-size: 1.1em;
    box-shadow: 0px 3px 4px #AAA!important;
    width: 43px!important;
    height: 43px!important;
    min-width: 43px!important;
    min-height: 43px!important;
    padding: 0!important;
    align-items: center;
    justify-content: center;
}

.cb-access-requests-pagination .item:not(.active):not(.cb-ellipsis-item) {
    color: #2A4365!important;
    background-color: #A6D997!important;
}

.cb-access-requests-pagination .item.active {
    color: $light-blue!important;
    background-color: $segurtempo-violet!important;
}

.cb-access-requests-pagination .item.active,
.cb-access-requests-pagination .cb-ellipsis-item {
    cursor: default!important;
}

.cb-access-requests-pagination .item:not(.active):hover,
.cb-access-requests-pagination .cb-ellipsis-item {
    background-color: $segurtempo-sky-blue!important;
}

.cb-access-requests-pagination .item .icon {
    margin: 0!important;
}