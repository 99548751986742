@import '../../../assets/styles/Colors.scss';

.cb-modality-cards-segment {
    border-color: transparent-black(0.09)!important;
}

.cb-modality-cards-segment:not(.cb-loading) {
    padding-top: 4em!important;
    padding-bottom: 4em!important;
}

.cb-modality-cards-segment.cb-loading {
    padding-top: 5em!important;
    padding-bottom: 5em!important;
}

.cb-modality-card {
    user-select: none;
}

.cb-modality-card .cb-modality-name {
    word-break: break-all;
}

.cb-modality-card:hover .cb-modality-name {
    color: $segurtempo-sky-blue;
}

.cb-modality-card:hover .cb-modality-picture {
    opacity: 0.7;
}