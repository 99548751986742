@import '../../../assets/styles/Colors.scss';


.cb-quote-statistics-form .cb-common-fields input[type=date] {
    border: 1px solid $segurtempo-violet!important;
}

.cb-quote-statistics-form .cb-statistics-header {
    display: flex;
    align-items: center;
    color :$segurtempo-white!important;

}

.cb-quote-statistics-form .cb-statistics-header .cb-info-tooltip-icon {
    font-size: 1em!important;
    padding-bottom: 1.1em!important;
}

.cb-quote-statistics-form .accordion .title {
    display: flex;
    align-items: center;
}

.cb-quote-statistics-form .accordion .title .checkbox {
    margin-right: 1em;
}

.cb-quote-statistics-form .accordion .content {
    padding: 1em 3em 3em!important;
}