@import '../../../assets/styles/Colors.scss';

.cb-quote-files-wrapper {
    max-height: 73vh;
    overflow-y: auto;
    position: relative;

    .cb-quote-files-toolbar {
        padding: 10px;
        border-bottom: solid 1px #ccc;
        display: flex;
        align-items: center;

        .path {
            flex: 1 1 auto;
            padding: 0 10px;

            .nav-box {
                border: solid 1px;
                width: 70%;
                height: 100%;
                background-color: #fff;

                button {
                    box-shadow: none !important;
                    margin-right: 10px;
                }
            }
        }
    }

    .cb-quote-files-datatable {
        width: 100%;
        display: block;

        table {
            width: 100%;
            border-collapse: collapse;

            .text-center {
                text-align: center;
            }

            thead {
                tr {
                    th {
                        padding: 10px;
                        text-align: left;
                        position: relative;

                        &::after {
                            content: ' ';
                            width: 1px;
                            position: absolute;
                            right: 1px;
                            height: 20px;
                            background-color: #ccc;
                            top: 50%;
                            transform: translateY(-50%);
                        }

                        &:last-child::after {
                            content: none;
                        }
                    }
                }
            }

            tbody {
                tr {
                    cursor: pointer;
                    background-color: #ffffff;

                    &:hover {
                        background-color: #f1f1f1;

                    }

                    td {
                        padding: 10px;

                        .icon {
                            font-size: 1.6rem;
                            vertical-align: middle;
                        }
                    }

                    border-width: 1px 0;
                    border-style: solid;
                    border-color: #ccc;
                }
            }
        }
    }

    .cb-quote-files-gallery {
        display: flex;
        flex-wrap: wrap;
        padding: 10px;
        background-color: #f9f9f9;

        .cb-file-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            max-width: 300px;
            min-width: 200px;
            margin: 0.5em;
            user-select: none;
            transition: box-shadow 0.2s;
            flex: 1 1 0;
        }
    }
}



@media only screen and (max-width: 767px) {
    .cb-quote-files-gallery .cb-file-item {
        max-width: 100%;
        width: 100%;
    }
}

.cb-file-item:not(.cb-highlighted) {
    justify-content: space-between;
    padding: 1em 1em 0.5em;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: transparentize($segurtempo-sky-blue, 0.5);
}

.cb-file-item.cb-highlighted {
    justify-content: center;
    padding: 1.5em 1em;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 3px 8px;
    background-color: transparentize($segurtempo-sky-blue, 0.7);
}

.cb-quote-files-gallery .cb-file-item:hover {
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
        rgba(0, 0, 0, 0.3) 0px 7px 13px -3px,
        rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    cursor: pointer;
}

.cb-quote-files-gallery .cb-file-item .icon {
    font-size: 3em;
    line-height: 1;
    vertical-align: middle;
}

.cb-quote-files-gallery .cb-file-item .cb-loading-icon {
    color: #C4C4C4;
}

.cb-quote-files-gallery .cb-file-item .cb-file-upload-date {
    font-size: smaller;
}

.cb-quote-files-gallery .cb-file-item .cb-file-name {
    text-align: center;
    word-break: break-word;
}

.cb-quote-files-gallery .cb-file-item:not(.cb-highlighted) .cb-file-name {
    margin: 1em 0 0.5em 0;
}

.cb-quote-files-gallery .cb-file-item.cb-highlighted .cb-file-name {
    margin: 0.5em 0 0;
}

.cb-quote-files-gallery .cb-empty-message {
    width: 60% !important;
    margin: 1em auto 0.5em !important;
}

.cb-quote-files-wrapper .cb-quote-generic-actions {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 2px;
    z-index: 1;
    padding: 0 10px 10px;
}

.cb-quote-files-wrapper .ui.button {
    border-radius: 0 !important;
    box-shadow: 0 0 0 1px #27292A inset !important;
    color: #27292A !important;
    margin-right: 10px;
}

.cb-quote-files-wrapper .cb-quote-generic-actions .ui.button:last-child {
    margin-right: 0 !important;
}

.cb-quote-files-wrapper .cb-quote-generic-actions .ui.button:not(:last-child) {
    margin-right: 2px !important;
}

.cb-quote-files-wrapper input[type=file] {
    display: none;
}