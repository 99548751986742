@import '../../../assets/styles/Colors.scss';

.cb-shortcut-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 250px;
    border: 1px solid #DDD;
    border-radius: 2em;
    background-color: transparent-white(0.5);
    user-select: none;
    padding: 4em 0;
    flex: 1 0 0;
    margin: 0.5em 1em;
}

.cb-shortcut-card:hover {
    cursor: pointer;
    box-shadow: 0px 0px 5px #999;
}

.cb-shortcut-card .cb-shortcut-title {
    text-align: center;
    font-size: calc(1.4em + 0.5vw);
    color: $segurtempo-violet;
}

.cb-shortcut-card:hover .cb-shortcut-title {
    color: $segurtempo-grey;
}