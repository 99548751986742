@import '../../assets/styles/Colors.scss';

.cb-not-found-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 5vh;
    background-image: linear-gradient(to bottom right, $segurtempo-red, $segurtempo-violet, $segurtempo-violet);
}

.cb-not-found-wrapper img {
    width: 20vw;
    min-width: 200px;
    -webkit-filter: drop-shadow(0 0 3px transparent-black(0.7));
    filter: drop-shadow(0 0 3px transparent-black(0.7));
}

.cb-not-found-wrapper > div {
    text-align: center;
}

.cb-not-found-wrapper h1 {
    margin: 0;
    font-size: 5em;
    color: $segurtempo-white;
}

.cb-not-found-wrapper h2 {
    font-size: 2.5em;
    color: $segurtempo-white;
}

.cb-not-found-wrapper .ui.button.cb-primary-button {
    margin-top: 1em;
}