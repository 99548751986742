@import '../../../assets/styles/Colors.scss';

.cb-users-table-wrapper {
    max-width: 100%;
    border-radius: 4px;
    background-color: $segurtempo-sky-blue;
    border: 1px solid rgba(34, 36, 38, 0.15);
    margin-top: 3em;
}

.cb-user-search-bar {
    padding: 1.3em 3.75em;
    border-bottom: 1px solid rgba(34, 36, 38, 0.15);
}

.cb-user-search-bar .cb-search-input {
    width: 50%;
}

.cb-user-search-bar .cb-search-input input {
    border: 2px solid #2A4365!important;
}


@media only screen and (max-width: 767px) {
    .cb-user-search-bar .cb-search-input {
        width: 100%;
    }
}

.cb-users-table-wrapper > table,
.cb-users-table-wrapper > table tr,
.cb-users-table-wrapper > table th,
.cb-users-table-wrapper > table td {
    background: transparent!important;
    margin: 0!important;
    border: none!important;
}


.cb-users-table-wrapper > table th,
.cb-users-table-wrapper > table td {
    font-size: 1.1em;
}

.cb-users-table-wrapper > table tr th,
.cb-users-table-wrapper > table tr:not(:last-child) td {
    border-bottom: 1px solid #D4D4D4!important;
}

.cb-users-table-wrapper > table tr:nth-child(2n+1) td {
    background: white!important;
}

.cb-users-table-wrapper > table th {
    color: $dark-blue!important;
}

.cb-users-table-wrapper > table th:first-child,
.cb-users-table-wrapper > table td:first-child {
    padding-left: 3rem!important;
}

.cb-users-table-wrapper > table td {
    color: #2E3B52!important;
}

.cb-users-table-wrapper > table tr.cb-user:hover {
    cursor: pointer;
}

.cb-users-table-wrapper > table tr:hover td {
    background: $segurtempo-sky-blue!important;
    color: white!important;
}

.cb-user-management-modal .actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.cb-user-management-modal .cb-deregister-user {
    color: darkred;
    user-select: none;
    padding: 0.4em 1em;
    border: 1px solid transparent;
}

.cb-user-management-modal .cb-deregister-user:hover {
    background-color: #EEE;
    border: 1px solid lightgrey;
    border-radius: 5px;
    cursor: pointer;
}

.cb-user-management-modal .cb-deregister-user .icon {
    margin-right: 0.5em;
}