@import '../../../assets/styles/Colors.scss';

.cb-login-form {
    margin: 1em 0;
}

.ui.form.cb-login-form .field label {
    color: $segurtempo-violet;
}

.ui.form.cb-login-form .field .action.input .cb-show-password-button {
    background-color: $mid-dark-grey;
    color: transparent-black(0.8);
    border-radius: 0;
}

.ui.form.cb-login-form .field .action.input .cb-show-password-button:hover {
    background-color: $light-grey;
    color: black;
}