@import '../../../assets/styles/Colors.scss';

.cb-secondary-button {
    background-color: transparent!important;
    color: $segurtempo-violet!important;
    border: 1px solid $segurtempo-violet!important;
}

.cb-secondary-button:hover {
    background-color: transparent-white(0.7)!important;
}