@import '../../../assets/styles/Colors.scss';


//--------------
//- Primary
//--------------
.cb-sidebar-external-link {
    display: block;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    padding: 1.2em 2em;
    margin-top: 4em;
    background-color: $segurtempo-white;
    opacity: 0.6;
    color: #111;
}

.cb-sidebar-external-link:hover {
    background-color: $segurtempo-white;
    opacity: 0.9;
    color: #111;
}

.cb-sidebar-external-link .cb-sidebar-external-link-content {
    font-size: 1.1em!important;
    text-align: center;
    font-weight: bold;
}